import { Currencies, Money } from 'ts-money'
import Invoice, { DateRangeFilter, InvoiceJSON, PaymentMethod } from './Invoice'

export default class Payment {
  id: number
  branchID: number
  amount: Money
  createdAt: Date
  paymentMethod: PaymentMethod
  transactionID: string
  notes: string
  paymentAccountID: number
  invoices: Invoice[]

  constructor(json: PaymentJSON) {
    this.id = json.id
    this.branchID = json.branchID

    if (json.invoices) {
      this.invoices = json.invoices.map(invoiceJSON => new Invoice(invoiceJSON))
    } else {
      this.invoices = []
    }

    this.amount = Money.fromInteger(json.amount, Currencies.MYR)
    this.createdAt = new Date(json.createdAt)
    this.paymentMethod = PaymentMethod.byID(json.paymentMethod)
    this.transactionID = json.transactionID
    this.notes = json.notes

    this.paymentAccountID = json.paymentAccountID
  }

  get [Symbol.toStringTag]() {
    return 'Payment'
  }
}

export interface PaymentJSON {
  id: number
  branchID: number
  amount: number
  createdAt: string
  paymentMethod: string
  notes: string
  transactionID: string
  paymentAccountID: number
  invoices: InvoiceJSON[]
}

export interface PaymentFilters {
  dateRange: DateRangeFilter | null
  paymentMethod: PaymentMethod | null
  paymentAccount: number | null
  branch: number | null
}