import MerchantPointsTransaction, { MerchantPointsTransactionJSON } from '../model/MerchantPointsTransaction'
import PaginationInfo from '../model/PaginationInfo'
import LoopError from '../store/errors/LoopError'
import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, token, urlForEndpoint } from './helpers'

export const getMerchantPointsTransactions = async (sorting: string = 'id', page: number = 1, limit: number = 30, search: string): Promise<MerchantPointsTransactionsResponse> => {
  // Build request
  const url = urlForEndpoint(`merchant-points/transactions`, {
    sorting,
    page,
    limit,
    search,
  })
  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { merchantPointsTransactions: merchantPointsTransactionsJSON, paginationInfo } = await parseResponse(response)

    let merchantPointsTransactions = merchantPointsTransactionsJSON.map((merchantPointsTransactionJSON: MerchantPointsTransactionJSON) => new MerchantPointsTransaction(merchantPointsTransactionJSON))

    return {
      merchantPointsTransactions,
      paginationInfo,
      requestParams: {
        sorting,
        page,
        limit,
        search,
      },
    }

  } catch (err) {
    throw new LoopError(err, { sorting, page, limit, search })
  }
}

export const getMerchantPointsTransactionByID = async (merchantPointsTransactionID: number): Promise<MerchantPointsTransaction> => {

  // Build request
  const url = urlForEndpoint(`merchant-points/transactions/${merchantPointsTransactionID}`)

  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { merchantPointsTransaction } = await parseResponse(response)
    return new MerchantPointsTransaction(merchantPointsTransaction as MerchantPointsTransactionJSON)
  } catch (err) {
    throw new LoopError(err, { merchantPointsTransactionID })
  }

}

export const putMerchantPointsTransaction = async (payload: PutMerchantPointsTransactionRequestParams): Promise<MerchantPointsTransaction> => {
  let { customerID, notes, amount, merchantPointsTransactionID } = payload

  // Build request
  const url = urlForEndpoint(`merchant-points/transactions/${merchantPointsTransactionID}`)

  const request = newRequest(HTTPMethods.PUT, token())
  request.body = JSON.stringify({
    customerID,
    notes,
    amount,
  })

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { merchantPointsTransaction } = await parseResponse(response)
    return new MerchantPointsTransaction(merchantPointsTransaction as MerchantPointsTransactionJSON)
  } catch (err) {
    throw new LoopError(err, payload)
  }
}

export const deleteMerchantPointsTransaction = async (merchantPointsTransactionID: number): Promise<MerchantPointsTransaction> => {
  // Build request
  const url = urlForEndpoint(`merchant-points/transactions/${merchantPointsTransactionID}`)

  const request = newRequest(HTTPMethods.DELETE, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { merchantPointsTransaction } = await parseResponse(response)
    return new MerchantPointsTransaction(merchantPointsTransaction as MerchantPointsTransactionJSON)
  } catch (err) {
    throw new LoopError(err, { merchantPointsTransactionID })
  }
}

export const postMerchantPointsTransaction = async (payload: PostMerchantPointsTransactionRequestParams): Promise<MerchantPointsTransaction> => {

  let { customerID, notes, amount } = payload

  // Build request
  const url = urlForEndpoint(`merchant-points/transactions`)

  const request = newRequest(HTTPMethods.POST, token())
  request.body = JSON.stringify({
    customerID, notes, amount,
  })

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { merchantPointsTransaction } = await parseResponse(response)
    return new MerchantPointsTransaction(merchantPointsTransaction as MerchantPointsTransactionJSON)
  } catch (err) {
    throw new LoopError(err, payload)
  }
}

export interface MerchantPointsTransactionIDsResponse {
  merchantPointsTransactionIDs: number[]
  requestParams: MerchantPointsTransactionsRequestParams
}


export interface MerchantPointsTransactionsResponse {
  merchantPointsTransactions: MerchantPointsTransaction[]
  paginationInfo: PaginationInfo
  requestParams: MerchantPointsTransactionsRequestParams
}

export interface MerchantPointsTransactionsErrorResponse {
  error: Error
  requestParams: MerchantPointsTransactionsRequestParams
}

export interface MerchantPointsTransactionsRequestParams {
  sorting: string
  page: number
  limit: number
  search: string
}

export interface MerchantPointsTransactionByIDRequestParams {
  merchantPointsTransactionID: number
}

export interface MerchantPointsTransactionByIDErrorResponse {
  error: Error
  requestParams: MerchantPointsTransactionByIDRequestParams
}

export interface PostMerchantPointsTransactionRequestParams {
  amount: number
  customerID: number
  notes: string | null
}

export interface PostMerchantPointsTransactionErrorResponse {
  error: Error
  requestParams: PostMerchantPointsTransactionRequestParams
}

export interface PutMerchantPointsTransactionRequestParams extends PostMerchantPointsTransactionRequestParams {
  merchantPointsTransactionID: number
}

export interface PutMerchantPointsTransactionErrorResponse {
  error: Error
  requestParams: PutMerchantPointsTransactionRequestParams
}