import Invoice, { InvoiceFilters, InvoiceJSON, PaymentMethod } from '../model/Invoice'
import PaginationInfo from '../model/PaginationInfo'
import Payment, { PaymentJSON } from '../model/Payment'
import LoopError from '../store/errors/LoopError'
import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, token, urlForEndpoint } from './helpers'

const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result as string
      resolve(result);
    }
    reader.onerror = () => {throw new Error("Failed to read blob to base64")}
    reader.readAsDataURL(blob);
  });
}

const base64toBlob = (data: string) => {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  const base64WithoutPrefix = data.substring('data:application/pdf;base64,'.length);

  const bytes = atob(base64WithoutPrefix);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: 'application/pdf' });
};

export const getStatementOfAccountPDF = async (branchID: number, customerID: number, startDate: string, endDate: string, showUnpaidOnly: boolean): Promise<Blob> => {

  // Build request
  let url = urlForEndpoint(`statement-of-account`, {
    branchID, customerID, startDate, endDate, showUnpaidOnly
  })
  let request = newRequest(HTTPMethods.GET, token())

  // Handle errors and return response
  try {
    const response = await fetchWithErrors(url, request)
    if (response.status != 200) {
      throw new Error('pdf download failed');
    }
    let blobData = await response.blob()


    // return blobData

    let base64Data = await blobToBase64(blobData)

    return base64toBlob(base64Data)
  } catch (err) {
    throw new LoopError(err, { branchID, customerID, startDate, endDate })
  }

}


