import { createSelector } from 'reselect'
import { MerchantPointsTransactionsRequestParams } from '../../api/merchantPointsTransactions'
import MerchantPointsTransaction from '../../model/MerchantPointsTransaction'
import { RootReducerState } from '../'
import { MerchantPointsTransactionsReducerPage } from '../reducers/merchantPointsTransactions'

export const hashKeyForMerchantPointsTransactionPage = ({
                                     sorting,
                                     page,
                                     limit,
                                     search = "",
                                   }: MerchantPointsTransactionsRequestParams): string => `${page},${limit},${sorting},${search}`

export const merchantPointsTransactionsSelector = (state: RootReducerState): { [id: number]: MerchantPointsTransaction } => state.merchantPointsTransactions.byID

const errorsSelector = (state: RootReducerState): { [id: number]: Error | null } => state.merchantPointsTransactions.errorByID
const merchantPointsTransactionIsFetchingByIDSelector = (state: RootReducerState): { [id: number]: boolean } => state.merchantPointsTransactions.isFetchingByID

const merchantPointsTransactionPageSelector = (state: RootReducerState, props: any): MerchantPointsTransactionsReducerPage => state.merchantPointsTransactions.pages[hashKeyForMerchantPointsTransactionPage(props)] || new MerchantPointsTransactionsReducerPage()
export const merchantPointsTransactionFlashMessageSelector = (state: RootReducerState): string | null => state.merchantPointsTransactions.successFlashMessage

// TODO: fix for react router
export const merchantPointsTransactionIDFromPropsSelector = (state: RootReducerState, props: any): number => {
  return +props.merchantPointsTransactionID || -1
}

export const merchantPointsTransactionWithIDFromProps = createSelector(
  [merchantPointsTransactionsSelector, merchantPointsTransactionIDFromPropsSelector],
  (merchantPointsTransactions, merchantPointsTransactionID) => merchantPointsTransactions[merchantPointsTransactionID],
)

export const errorForMerchantPointsTransactionWithIDFromProps = createSelector(
  [errorsSelector, merchantPointsTransactionIDFromPropsSelector],
  (merchantPointsTransactions, merchantPointsTransactionID) => merchantPointsTransactions[merchantPointsTransactionID],
)

export const isFetchingForMerchantPointsTransactionWithIDFromProps = createSelector(
  [merchantPointsTransactionIsFetchingByIDSelector, merchantPointsTransactionIDFromPropsSelector],
  (merchantPointsTransactions, merchantPointsTransactionID) => merchantPointsTransactions[merchantPointsTransactionID] || false,
)

export const merchantPointsTransactionsByPageSelector = createSelector(
  [merchantPointsTransactionsSelector, merchantPointsTransactionPageSelector],
  (merchantPointsTransactions, page) => {
    if (page == null || page.childIDs == null) {
      return null
    }
    return page.childIDs.map(merchantPointsTransactionID => merchantPointsTransactions[merchantPointsTransactionID])
  },
)

export const paginationInfoByPageSelector = createSelector(
  [merchantPointsTransactionPageSelector],
  (page) => page.paginationInfo,
)

export const pageIsFetchingSelector = createSelector(
  [merchantPointsTransactionPageSelector],
  (page) => page.isFetching || false,
)

export const pageIsInitializedSelector = createSelector(
  [merchantPointsTransactionPageSelector],
  (page) => page.isInitialized || false,
)

export const pageErrorSelector = createSelector(
  [merchantPointsTransactionPageSelector],
  (page) => page.error || null,
)

