export default class MerchantPointsProgram {
  pointsValidityDays: number
  accrualRate: number
  redemptionValue: number
  redemptionIncrement: number
  minimumRedemptionAmount: number

  constructor(json: PartnerAgentJSON) {
    this.pointsValidityDays = json.pointsValidityDays
    this.accrualRate = json.accrualRate
    this.redemptionValue = json.redemptionValue
    this.redemptionIncrement = json.redemptionIncrement
    this.minimumRedemptionAmount = json.minimumRedemptionAmount
  }

  get [Symbol.toStringTag]() {
    return 'MerchantPointsProgram'
  }
}

export interface PartnerAgentJSON {
  pointsValidityDays: number
  accrualRate: number
  redemptionValue: number
  redemptionIncrement: number
  minimumRedemptionAmount: number
}
