import MerchantPointsProgram from '../model/MerchantPointsProgram'
import LoopError from '../store/errors/LoopError'
import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, token, urlForEndpoint } from './helpers'

export const getPointsProgram = async (): Promise<MerchantPointsProgram | null> => {

  // Build request
  const url = urlForEndpoint(`merchant-points/program`)
  const request = newRequest(HTTPMethods.GET, token())

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Check for 404
    if (response.status == 404) {
      // Points program is not enabled
      return null
    }

    // Handle errors and return response
    const { pointsProgram: pointsProgramJSON } = await parseResponse(response)
    return new MerchantPointsProgram(pointsProgramJSON)

  } catch (err) {
    throw new LoopError(err, { })
  }
}

