import { Money } from 'ts-money'
import Category from './Category'
import CategoryValue, { CategoryValueJSON } from './CategoryValue'
import { ProductQuantity, ProductQuantityJSON } from './Promo'

export default class Dashboard {
  invoiceStats: {
    paidCount: number
    unpaidCount: number
    totalPaidSalesAmount: Money
    totalUnpaidSalesAmount: Money
    uniqueCustomers: number
    newCustomers: number
    topProducts: ProductQuantity[]
    categoryRevenue: CategoryValue[]
    timeSeries: SalesData[]
  }
  paymentStats: {
    transactionCount: number
    totalPaymentAmount: Money
    paymentAccountValues: PaymentAccountData[]
  }

  constructor(json: DashboardJSON) {
    this.invoiceStats = {
      paidCount:              json.invoiceStats.paidCount,
      unpaidCount:            json.invoiceStats.unpaidCount,
      totalPaidSalesAmount:   Money.fromInteger(json.invoiceStats.totalPaidSalesAmount, 'MYR'),
      totalUnpaidSalesAmount: Money.fromInteger(json.invoiceStats.totalUnpaidSalesAmount, 'MYR'),
      uniqueCustomers:        json.invoiceStats.uniqueCustomers,
      newCustomers:           json.invoiceStats.newCustomers,
      topProducts:            json.invoiceStats.topProducts.map(pqJson => new ProductQuantity(pqJson)),
      categoryRevenue:        json.invoiceStats.categoryRevenue.map(crJson => new CategoryValue(crJson)),
      timeSeries:             json.invoiceStats.timeSeries.map(tsJson => new SalesData(tsJson)),
    }
    this.paymentStats = {
      transactionCount:   json.paymentStats.transactionCount,
      totalPaymentAmount: Money.fromInteger(json.paymentStats.totalPaymentAmount, 'MYR'),
      paymentAccountValues:  json.paymentStats.paymentAccountValues.map(pbmJson => new PaymentAccountData(pbmJson))
    }
  }

  get [Symbol.toStringTag]() {
    return 'Dashboard'
  }
}

export interface DashboardJSON {
  invoiceStats: {
    paidCount: number
    unpaidCount: number
    totalUnpaidSalesAmount: number
    totalPaidSalesAmount: number
    uniqueCustomers: number
    newCustomers: number
    topProducts: ProductQuantityJSON[]
    categoryRevenue: CategoryValueJSON[]
    timeSeries: SalesDataJSON[]
  }
  paymentStats: {
    transactionCount: number
    totalPaymentAmount: number
    paymentAccountValues: PaymentAccountDataJSON[]
  }
}

export class SalesData {
  time: Date
  grossTotal: Money
  netTotal: Money
  invoiceCount: number

  constructor(json: SalesDataJSON) {
    this.time = new Date(json.time)
    this.grossTotal = Money.fromInteger(json.grossTotalAmount, 'MYR')
    this.netTotal = Money.fromInteger(json.netTotalAmount, 'MYR')
    this.invoiceCount = json.invoiceCount
  }
}

export interface SalesDataJSON {
  time: string
  grossTotalAmount: number
  netTotalAmount: number
  invoiceCount: number
}

export class PaymentAccountData {
  paymentAccount: string
  amount: Money
  count: number

  constructor(json: PaymentAccountDataJSON) {
    this.paymentAccount = json.paymentAccount
    this.amount = Money.fromInteger(json.amount, 'MYR')
    this.count = json.count
  }
}

export interface PaymentAccountDataJSON {
  paymentAccount: string
  amount: number
  count: number
}
