import { push } from '@lagunovsky/redux-react-router'
import { Cmd, Loop, loop } from 'redux-loop'
import API from '../../api'
import PaginationInfo from '../../model/PaginationInfo'
import ProductCreditTransaction from '../../model/ProductCreditTransaction'
import {
  CreateProductCreditTransactionAction,
  DeleteProductCreditTransactionAction,
  FetchProductCreditTransactionByIDAction,
  FetchProductCreditTransactionsAction,
  ProductCreditTransactionsActions,
  rejectCreateProductCreditTransaction,
  RejectCreateProductCreditTransactionAction,
  rejectDeleteProductCreditTransaction,
  RejectDeleteProductCreditTransactionAction,
  rejectFetchProductCreditTransactionByID,
  RejectFetchProductCreditTransactionByIDAction,
  rejectFetchProductCreditTransactions,
  RejectFetchProductCreditTransactionsAction,
  rejectUpdateProductCreditTransaction,
  RejectUpdateProductCreditTransactionAction,
  resolveCreateProductCreditTransaction,
  ResolveCreateProductCreditTransactionAction,
  resolveDeleteProductCreditTransaction,
  ResolveDeleteProductCreditTransactionAction,
  resolveFetchProductCreditTransactionByID,
  ResolveFetchProductCreditTransactionByIDAction,
  resolveFetchProductCreditTransactions,
  ResolveFetchProductCreditTransactionsAction,
  resolveUpdateProductCreditTransaction,
  ResolveUpdateProductCreditTransactionAction,
  UpdateProductCreditTransactionAction,
} from '../actions/productCreditTransactions'
import * as actionTypes from '../constants/ActionTypes'
import { CLEAR_FLASH_MESSAGES } from '../constants/ActionTypes'
import { hashKeyForProductCreditTransactionPage } from '../selectors/productCreditTransactions'

interface IDMappedProductCreditTransactions {
  [id: number]: ProductCreditTransaction
}

interface ProductCreditTransactionsReducerState {
  byID: IDMappedProductCreditTransactions
  pages: { [hash: string]: ProductCreditTransactionsReducerPage }
  isFetchingByID: { [id: number]: boolean }
  successFlashMessage: string | null
  errorByID: { [id: number]: Error | null }
}

export class ProductCreditTransactionsReducerPage {
  error: Error | null = null
  isFetching: boolean = false
  childIDs: number[] = []
  paginationInfo: PaginationInfo = new PaginationInfo()
  isInitialized: boolean = false
}

const initialState: ProductCreditTransactionsReducerState = {
  byID:                {},
  pages:               {},
  isFetchingByID:      {},
  successFlashMessage: null,
  errorByID:           {},
}

export default (state: ProductCreditTransactionsReducerState = initialState, action: ProductCreditTransactionsActions): ProductCreditTransactionsReducerState | Loop<ProductCreditTransactionsReducerState> => {
  switch (action.type) {

    case actionTypes.FETCH_PRODUCT_CREDIT_TRANSACTIONS: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchProductCreditTransactionsAction
          const { sorting, page, limit, search } = payload

          // Build page object
          const pageObject = new ProductCreditTransactionsReducerPage()
          pageObject.isFetching = true
          pageObject.isInitialized = true

          // Create a hash key for the page
          const pageKey = hashKeyForProductCreditTransactionPage(payload)

          // Set state and fetch
          return loop(
            Object.assign({}, state, {
              pages: Object.assign({}, state.pages, {
                [pageKey]: pageObject,
              }),
            }),
            Cmd.run(API.getProductCreditTransactions, {
              successActionCreator: resolveFetchProductCreditTransactions,
              failActionCreator:    rejectFetchProductCreditTransactions,
              args:                 [sorting, page, limit, search],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchProductCreditTransactionsAction
          const { productCreditTransactions, paginationInfo, requestParams } = payload

          const pageKey = hashKeyForProductCreditTransactionPage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching:     false,
            error:          null,
            childIDs:       productCreditTransactions.map((productCreditTransaction: ProductCreditTransaction) => productCreditTransaction.id),
            paginationInfo: paginationInfo,
          }

          // Map productCreditTransaction ids to productCreditTransactions
          let idMappedProductCreditTransactions: IDMappedProductCreditTransactions = {}
          productCreditTransactions.forEach(productCreditTransaction => {
            idMappedProductCreditTransactions[productCreditTransaction.id] = productCreditTransaction
          })


          // Place in correct page
          return Object.assign({}, state, {
            byID:  Object.assign({}, state.byID, idMappedProductCreditTransactions),
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }

        case false: {
          const { payload } = action as RejectFetchProductCreditTransactionsAction
          const { error, requestParams } = payload

          // Create a hash key for the page
          const pageKey = hashKeyForProductCreditTransactionPage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching: false,
            error,
          }

          // Place in correct page
          return Object.assign({}, state, {
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }
      }

      break
    }

    case actionTypes.FETCH_PRODUCT_CREDIT_TRANSACTION_BY_ID: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchProductCreditTransactionByIDAction
          const { productCreditTransactionID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [productCreditTransactionID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [productCreditTransactionID]: null,
              },
            },
            Cmd.run(API.getProductCreditTransactionByID, {
              successActionCreator: resolveFetchProductCreditTransactionByID,
              failActionCreator:    rejectFetchProductCreditTransactionByID,
              args:                 [productCreditTransactionID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchProductCreditTransactionByIDAction
          const { productCreditTransaction } = payload

          return {
            ...state,
            byID:           {
              ...state.byID,
              [productCreditTransaction.id]: productCreditTransaction,
            },
            isFetchingByID: {
              ...state.isFetchingByID,
              [productCreditTransaction.id]: false,
            },
          }
        }

        case false: {
          const { payload } = action as RejectFetchProductCreditTransactionByIDAction
          const { error, requestParams } = payload
          const { productCreditTransactionID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [productCreditTransactionID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [productCreditTransactionID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.UPDATE_PRODUCT_CREDIT_TRANSACTION: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as UpdateProductCreditTransactionAction
          const { productCreditTransactionID, name, company, phoneNumber, emailAddress } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID:      {
                ...state.isFetchingByID,
                [productCreditTransactionID]: true,
              },
              errorByID:           {
                ...state.errorByID,
                [productCreditTransactionID]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.putProductCreditTransaction, {
              successActionCreator: resolveUpdateProductCreditTransaction,
              failActionCreator:    rejectUpdateProductCreditTransaction,
              args:                 [productCreditTransactionID, name, company, phoneNumber, emailAddress],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveUpdateProductCreditTransactionAction
          const { productCreditTransaction } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [productCreditTransaction.id]: productCreditTransaction,
              },
              isFetchingByID:      {
                ...state.isFetchingByID,
                [productCreditTransaction.id]: false,
              },
              successFlashMessage: 'Partner agent successfully updated!',
            },
            Cmd.list([
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectUpdateProductCreditTransactionAction
          const { error, requestParams } = payload
          const { productCreditTransactionID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [productCreditTransactionID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [productCreditTransactionID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.DELETE_PRODUCT_CREDIT_TRANSACTION: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as DeleteProductCreditTransactionAction
          const { productCreditTransactionID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [productCreditTransactionID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [productCreditTransactionID]: null,
              },
            },
            Cmd.run(API.deleteProductCreditTransaction, {
              successActionCreator: resolveDeleteProductCreditTransaction,
              failActionCreator:    rejectDeleteProductCreditTransaction,
              args:                 [productCreditTransactionID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveDeleteProductCreditTransactionAction
          const { productCreditTransaction } = payload

          // Deletey-poo
          let byID = {
            ...state.byID,
          }
          delete byID[productCreditTransaction.id]

          return loop(
            {
              ...state,
              byID,
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [productCreditTransaction.id]: false,
              },
              successFlashMessage: 'Partner agent successfully deleted!',
            },
            Cmd.list([
              Cmd.action(push('/product-credit-transactions')),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectDeleteProductCreditTransactionAction
          const { error, requestParams } = payload
          const { productCreditTransactionID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [productCreditTransactionID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [productCreditTransactionID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.CREATE_PRODUCT_CREDIT_TRANSACTION: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as CreateProductCreditTransactionAction
          const { name, company, phoneNumber, emailAddress } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID:      {
                ...state.isFetchingByID,
                [-1]: true,
              },
              errorByID:           {
                ...state.errorByID,
                [-1]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.postProductCreditTransaction, {
              successActionCreator: resolveCreateProductCreditTransaction,
              failActionCreator:    rejectCreateProductCreditTransaction,
              args:                 [name, company, phoneNumber, emailAddress],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveCreateProductCreditTransactionAction
          const { productCreditTransaction } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [productCreditTransaction.id]: productCreditTransaction,
              },
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [-1]: false,
              },
              successFlashMessage: 'Partner agent successfully created!',
            },
            Cmd.list([
              Cmd.action(push(`/product-credit-transactions/${productCreditTransaction.id}`)),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectCreateProductCreditTransactionAction
          const { error } = payload

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [-1]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [-1]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.CLEAR_FLASH_MESSAGES: {
      return {
        ...state,
        successFlashMessage: null,
      }
    }

  }

// Default
  return state
}
