import { push } from '@lagunovsky/redux-react-router'
import { Cmd, Loop, loop } from 'redux-loop'
import API from '../../api'
import Invoice from '../../model/Invoice'
import PaginationInfo from '../../model/PaginationInfo'
import {
  DeleteInvoiceAction,
  FetchInvoiceByIDAction,
  FetchInvoicesAction,
  FullyRefundInvoiceAction,
  InvoicesActions,
  MarkInvoiceFinalizedAction,
  rejectDeleteInvoice,
  RejectDeleteInvoiceAction,
  rejectFetchInvoiceByID,
  RejectFetchInvoiceByIDAction,
  rejectFetchInvoices,
  RejectFetchInvoicesAction,
  rejectFullyRefundInvoice,
  RejectFullyRefundInvoiceAction, RejectMarkInvoiceFinalizedAction,
  rejectMarkInvoicesFinalized,
  rejectUpdateInvoicePayment,
  RejectUpdateInvoicePaymentAction,
  resolveDeleteInvoice,
  ResolveDeleteInvoiceAction,
  resolveFetchInvoiceByID,
  ResolveFetchInvoiceByIDAction,
  resolveFetchInvoices,
  ResolveFetchInvoicesAction,
  resolveFullyRefundInvoice,
  ResolveFullyRefundInvoiceAction,
  ResolveMarkInvoiceFinalizedAction,
  resolveMarkInvoicesFinalized,
  resolveUpdateInvoicePayment,
  ResolveUpdateInvoicePaymentAction,
  UpdateInvoicePaymentAction,
} from '../actions/invoices'
import {
  CreatePaymentAction,
  rejectCreatePayment, RejectCreatePaymentAction,
  resolveCreatePayment,
  ResolveCreatePaymentAction,
} from '../actions/payments'

import * as actionTypes from '../constants/ActionTypes'
import { CLEAR_FLASH_MESSAGES } from '../constants/ActionTypes'
import { hashKeyForInvoicePage } from '../selectors/invoices'

interface IDMappedInvoices {
  [id: number]: Invoice
}

interface InvoicesReducerState {
  byID: IDMappedInvoices
  pages: { [hash: string]: InvoicesReducerPage }
  isFetchingByID: { [id: number]: boolean }
  successFlashMessage: string | null
  errorByID: { [id: number]: Error | null }
}

export class InvoicesReducerPage {
  error: Error | null = null
  isFetching: boolean = false
  childIDs: number[] = []
  paginationInfo: PaginationInfo = new PaginationInfo()
  isInitialized: boolean = false
}

const initialState: InvoicesReducerState = {
  byID:                {},
  pages:               {},
  isFetchingByID:      {},
  successFlashMessage: null,
  errorByID:           {},
}

export default (state: InvoicesReducerState = initialState, action: InvoicesActions): InvoicesReducerState | Loop<InvoicesReducerState> => {
  switch (action.type) {
    case actionTypes.FETCH_INVOICES: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchInvoicesAction
          const { sorting, page, limit, filters, search } = payload

          // Build page object
          const pageObject = new InvoicesReducerPage()
          pageObject.isFetching = true
          pageObject.isInitialized = true

          // Create a hash key for the page
          const pageKey = hashKeyForInvoicePage(payload)

          // Set state and fetch
          return loop(
            Object.assign({}, state, {
              pages: Object.assign({}, state.pages, {
                [pageKey]: pageObject,
              }),
            }),
            Cmd.run(API.getInvoices, {
              successActionCreator: resolveFetchInvoices,
              failActionCreator:    rejectFetchInvoices,
              args:                 [sorting, page, limit, filters, search],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchInvoicesAction
          const { invoices, paginationInfo, requestParams } = payload

          const pageKey = hashKeyForInvoicePage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching:     false,
            error:          null,
            childIDs:       invoices.map((invoice: Invoice) => invoice.id),
            paginationInfo: paginationInfo,
          }

          // Map invoice ids to invoices
          let idMappedInvoices: IDMappedInvoices = {}
          invoices.forEach(invoice => {
            idMappedInvoices[invoice.id] = invoice
          })


          // Place in correct page
          return Object.assign({}, state, {
            byID:  Object.assign({}, state.byID, idMappedInvoices),
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }

        case false: {
          const { payload } = action as RejectFetchInvoicesAction
          const { error, requestParams } = payload

          // Create a hash key for the page
          const pageKey = hashKeyForInvoicePage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching: false,
            error,
          }

          // Place in correct page
          return Object.assign({}, state, {
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }
      }

      break
    }

    case actionTypes.FETCH_INVOICE_BY_ID: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchInvoiceByIDAction
          const { invoiceID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [invoiceID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [invoiceID]: null,
              },
            },
            Cmd.run(API.getInvoiceByID, {
              successActionCreator: resolveFetchInvoiceByID,
              failActionCreator:    rejectFetchInvoiceByID,
              args:                 [invoiceID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchInvoiceByIDAction
          const { invoice } = payload

          return {
            ...state,
            byID:           {
              ...state.byID,
              [invoice.id]: invoice,
            },
            isFetchingByID: {
              ...state.isFetchingByID,
              [invoice.id]: false,
            },
          }
        }

        case false: {
          const { payload } = action as RejectFetchInvoiceByIDAction
          const { error, requestParams } = payload
          const { invoiceID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [invoiceID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [invoiceID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.DELETE_INVOICE: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as DeleteInvoiceAction
          const { invoiceID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [invoiceID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [invoiceID]: null,
              },
            },
            Cmd.run(API.deleteInvoice, {
              successActionCreator: resolveDeleteInvoice,
              failActionCreator:    rejectDeleteInvoice,
              args:                 [invoiceID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveDeleteInvoiceAction
          const { invoice } = payload

          // Deletey-poo
          let byID = {
            ...state.byID,
          }
          delete byID[invoice.id]

          return loop(
            {
              ...state,
              byID,
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [invoice.id]: false,
              },
              successFlashMessage: 'Invoice successfully deleted!',
            },
            Cmd.list([
              Cmd.action(push('/invoices')),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectDeleteInvoiceAction
          const { error, requestParams } = payload
          const { invoiceID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [invoiceID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [invoiceID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.FULLY_REFUND_INVOICE: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FullyRefundInvoiceAction
          const { invoiceID, refundReason, paymentAccountID, transactionID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [invoiceID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [invoiceID]: null,
              },
            },
            Cmd.run(API.postFullRefundInvoice, {
              successActionCreator: resolveFullyRefundInvoice,
              failActionCreator:    rejectFullyRefundInvoice,
              args:                 [invoiceID, refundReason, paymentAccountID, transactionID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFullyRefundInvoiceAction
          const { invoice } = payload

          let byID = {
            ...state.byID,
          }
          byID[invoice.id] = invoice

          return loop(
            {
              ...state,
              byID,
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [invoice.id]: false,
              },
              successFlashMessage: 'Invoice successfully refunded!',
            },
            Cmd.list([
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectFullyRefundInvoiceAction
          const { error, requestParams } = payload
          const { invoiceID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [invoiceID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [invoiceID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.UPDATE_INVOICE_PAYMENT: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as UpdateInvoicePaymentAction
          let { invoiceID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID:      {
                ...state.isFetchingByID,
                [invoiceID]: true,
              },
              errorByID:           {
                ...state.errorByID,
                [invoiceID]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.putInvoicePayment, {
              successActionCreator: resolveUpdateInvoicePayment,
              failActionCreator:    rejectUpdateInvoicePayment,
              args:                 [payload],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveUpdateInvoicePaymentAction
          const { payment } = payload

          let newByID = { ...state.byID }
          let newIsFetchingByID = { ...state.isFetchingByID }
          payment.invoices.forEach(i => {
            // The invoices in payment are stubs and so we need to fetch the whole thing
            let invoice = state.byID[i.id]
            if (invoice == null) {
              return
            }

            // Replace payment with updated version
            invoice.payments = invoice.payments.map(p => {
              if (p.id != payment.id) {
                return p
              } else {
                return payment
              }
            })

            // Replace in maps
            newByID[i.id] = invoice
            newIsFetchingByID[i.id] = false
          })


          return loop({
              ...state,
              byID:                newByID,
              isFetchingByID:      newIsFetchingByID,
              successFlashMessage: 'Invoice payment successfully updated!',
            },
            Cmd.list([
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectUpdateInvoicePaymentAction
          const { error, requestParams } = payload
          const { paymentID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [paymentID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [paymentID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.MARK_INVOICES_FINALIZED: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as MarkInvoiceFinalizedAction
          let { invoiceIDs } = payload

          let newIsFetchingByID = { ...state.isFetchingByID }
          let newErrorByID = { ...state.errorByID }
          invoiceIDs.forEach(id => {
            newIsFetchingByID[id] = true
            newErrorByID[id] = null
          })

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID:      newIsFetchingByID,
              errorByID:           newErrorByID,
              successFlashMessage: null,
            },
            Cmd.run(API.postInvoicesFinalize, {
              successActionCreator: resolveMarkInvoicesFinalized,
              failActionCreator:    rejectMarkInvoicesFinalized,
              args:                 [payload],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveMarkInvoiceFinalizedAction

          let newByID = { ...state.byID }
          let newIsFetchingByID = { ...state.isFetchingByID }
          payload.forEach(i => {
            // Replace in maps
            newByID[i.id] = i
            newIsFetchingByID[i.id] = false
          })

          return loop({
              ...state,
              byID:                newByID,
              isFetchingByID:      newIsFetchingByID,
              successFlashMessage: 'Invoices successfully finalized!',
            },
            Cmd.list([
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectMarkInvoiceFinalizedAction
          const { error, requestParams } = payload
          let {invoiceIDs} = requestParams

          let newIsFetchingByID = { ...state.isFetchingByID }
          let newErrorByID = { ...state.errorByID }
          invoiceIDs.forEach(id => {
            newIsFetchingByID[id] = false
            newErrorByID[id] = error
          })

          // Set state and fetch
          return {
              ...state,
              isFetchingByID:      newIsFetchingByID,
              errorByID:           newErrorByID,
              successFlashMessage: null,
          }
        }
      }

      break
    }

    case actionTypes.CREATE_PAYMENT: {
      let { success } = action
      switch (success) {

        case true: {
          let { payload } = action as ResolveCreatePaymentAction
          const { invoices } = payload

          let updatedInvoices: IDMappedInvoices = {}
          invoices.forEach(invoice => {
            updatedInvoices[invoice.id] = invoice
          })

          return {
            ...state,
            byID: {
              ...state.byID,
              ...updatedInvoices,
            },
          }
        }

      }

      break
    }

  }

  // Default
  return state
}
