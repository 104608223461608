import { push } from '@lagunovsky/redux-react-router'
import { Cmd, Loop, loop } from 'redux-loop'
import API from '../../api'
import PaginationInfo from '../../model/PaginationInfo'
import Vehicle from '../../model/Vehicle'
import {
  CreateVehicleAction,
  DeleteVehicleAction,
  FetchVehicleByIDAction,
  FetchVehiclesAction,
  VehiclesActions,
  rejectCreateVehicle,
  RejectCreateVehicleAction,
  rejectDeleteVehicle,
  RejectDeleteVehicleAction,
  rejectFetchVehicleByID,
  RejectFetchVehicleByIDAction,
  rejectFetchVehicles,
  RejectFetchVehiclesAction,
  rejectUpdateVehicle,
  RejectUpdateVehicleAction,
  resolveCreateVehicle,
  ResolveCreateVehicleAction,
  resolveDeleteVehicle,
  ResolveDeleteVehicleAction,
  resolveFetchVehicleByID,
  ResolveFetchVehicleByIDAction,
  resolveFetchVehicles,
  ResolveFetchVehiclesAction,
  resolveUpdateVehicle,
  ResolveUpdateVehicleAction,
  UpdateVehicleAction,
} from '../actions/vehicles'
import * as actionTypes from '../constants/ActionTypes'
import { CLEAR_FLASH_MESSAGES } from '../constants/ActionTypes'
import { hashKeyForVehiclePage } from '../selectors/vehicles'

interface IDMappedVehicles {
  [id: number]: Vehicle
}

interface VehiclesReducerState {
  byID: IDMappedVehicles
  pages: { [hash: string]: VehiclesReducerPage }
  isFetchingByID: { [id: number]: boolean }
  successFlashMessage: string | null
  errorByID: { [id: number]: Error | null }
}

export class VehiclesReducerPage {
  error: Error | null = null
  isFetching: boolean = false
  childIDs: number[] = []
  paginationInfo: PaginationInfo = new PaginationInfo()
  isInitialized: boolean = false
}

const initialState: VehiclesReducerState = {
  byID:                {},
  pages:               {},
  isFetchingByID:      {},
  successFlashMessage: null,
  errorByID:           {},
}

export default (state: VehiclesReducerState = initialState, action: VehiclesActions): VehiclesReducerState | Loop<VehiclesReducerState> => {
  switch (action.type) {
    case actionTypes.FETCH_VEHICLES: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchVehiclesAction
          const { sorting, page, limit, filters, search } = payload

          // Build page object
          const pageObject = new VehiclesReducerPage()
          pageObject.isFetching = true
          pageObject.isInitialized = true

          // Create a hash key for the page
          const pageKey = hashKeyForVehiclePage(payload)

          // Set state and fetch
          return loop(
            Object.assign({}, state, {
              pages: Object.assign({}, state.pages, {
                [pageKey]: pageObject,
              }),
            }),
            Cmd.run(API.getVehicles, {
              successActionCreator: resolveFetchVehicles,
              failActionCreator:    rejectFetchVehicles,
              args:                 [sorting, page, limit, filters, search],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchVehiclesAction
          const { vehicles, paginationInfo, requestParams } = payload

          const pageKey = hashKeyForVehiclePage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching:     false,
            error:          null,
            childIDs:       vehicles.map((vehicle: Vehicle) => vehicle.id),
            paginationInfo: paginationInfo,
          }

          // Map vehicle ids to vehicles
          let idMappedVehicles: IDMappedVehicles = {}
          vehicles.forEach(vehicle => {
            idMappedVehicles[vehicle.id] = vehicle
          })


          // Place in correct page
          return Object.assign({}, state, {
            byID:  Object.assign({}, state.byID, idMappedVehicles),
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }

        case false: {
          const { payload } = action as RejectFetchVehiclesAction
          const { error, requestParams } = payload

          // Create a hash key for the page
          const pageKey = hashKeyForVehiclePage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching: false,
            error,
          }

          // Place in correct page
          return Object.assign({}, state, {
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }
      }

      break
    }

    case actionTypes.FETCH_VEHICLE_BY_ID: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchVehicleByIDAction
          const { vehicleID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [vehicleID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [vehicleID]: null,
              },
            },
            Cmd.run(API.getVehicleByID, {
              successActionCreator: resolveFetchVehicleByID,
              failActionCreator:    rejectFetchVehicleByID,
              args:                 [vehicleID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchVehicleByIDAction
          const { vehicle } = payload

          return {
            ...state,
            byID:           {
              ...state.byID,
              [vehicle.id]: vehicle,
            },
            isFetchingByID: {
              ...state.isFetchingByID,
              [vehicle.id]: false,
            },
          }
        }

        case false: {
          const { payload } = action as RejectFetchVehicleByIDAction
          const { error, requestParams } = payload
          const { vehicleID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [vehicleID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [vehicleID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.UPDATE_VEHICLE: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as UpdateVehicleAction
          const { vehicleID} = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID:      {
                ...state.isFetchingByID,
                [vehicleID]: true,
              },
              errorByID:           {
                ...state.errorByID,
                [vehicleID]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.putVehicle, {
              successActionCreator: resolveUpdateVehicle,
              failActionCreator:    rejectUpdateVehicle,
              args:                 [payload],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveUpdateVehicleAction
          const { vehicle } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [vehicle.id]: vehicle,
              },
              isFetchingByID:      {
                ...state.isFetchingByID,
                [vehicle.id]: false,
              },
              successFlashMessage: 'Vehicle successfully updated!',
            },
            Cmd.list([
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ])
          )
        }

        case false: {
          const { payload } = action as RejectUpdateVehicleAction
          const { error, requestParams } = payload
          const { vehicleID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [vehicleID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [vehicleID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.DELETE_VEHICLE: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as DeleteVehicleAction
          const { vehicleID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [vehicleID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [vehicleID]: null,
              },
            },
            Cmd.run(API.deleteVehicle, {
              successActionCreator: resolveDeleteVehicle,
              failActionCreator:    rejectDeleteVehicle,
              args:                 [vehicleID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveDeleteVehicleAction
          const { vehicle } = payload

          // Deletey-poo
          let byID = {
            ...state.byID,
          }
          delete byID[vehicle.id]

          return loop(
            {
              ...state,
              byID,
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [vehicle.id]: false,
              },
              successFlashMessage: 'Vehicle successfully deleted!',
            },
            Cmd.list([
              Cmd.action(push('/vehicles')),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectDeleteVehicleAction
          const { error, requestParams } = payload
          const { vehicleID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [vehicleID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [vehicleID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.CREATE_VEHICLE: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as CreateVehicleAction

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID:      {
                ...state.isFetchingByID,
                [-1]: true,
              },
              errorByID:           {
                ...state.errorByID,
                [-1]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.postVehicle, {
              successActionCreator: resolveCreateVehicle,
              failActionCreator:    rejectCreateVehicle,
              args:                 [payload],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveCreateVehicleAction
          const { vehicle } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [vehicle.id]: vehicle,
              },
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [vehicle.id]: false,
              },
              successFlashMessage: 'Vehicle successfully created!',
            },
            Cmd.list([
              Cmd.action(push(`/vehicles/${vehicle.id}`)),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectCreateVehicleAction
          const { error } = payload

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [-1]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [-1]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.CLEAR_FLASH_MESSAGES: {
      return {
        ...state,
        successFlashMessage: null,
      }
    }

  }

  // Default
  return state
}
