import { Cmd, Loop, loop } from 'redux-loop'
import API from '../../api'
import PaginationInfo from '../../model/PaginationInfo'
import Payment from '../../model/Payment'
import {
  CreatePaymentAction,
  FetchPaymentByIDAction,
  FetchPaymentsAction,
  PaymentsActions, rejectCreatePayment, RejectCreatePaymentAction,
  rejectFetchPaymentByID,
  RejectFetchPaymentByIDAction,
  rejectFetchPayments,
  RejectFetchPaymentsAction, resolveCreatePayment, ResolveCreatePaymentAction,
  resolveFetchPaymentByID,
  ResolveFetchPaymentByIDAction,
  resolveFetchPayments,
  ResolveFetchPaymentsAction,
} from '../actions/payments'
import { CLEAR_FLASH_MESSAGES } from '../constants/ActionTypes'

import * as actionTypes from '../constants/ActionTypes'
import { hashKeyForPaymentPage } from '../selectors/payments'

interface IDMappedPayments {
  [id: number]: Payment
}

interface PaymentsReducerState {
  byID: IDMappedPayments
  pages: { [hash: string]: PaymentsReducerPage }
  isFetchingByID: { [id: number]: boolean }
  successFlashMessage: string | null
  errorByID: { [id: number]: Error | null }
}

export class PaymentsReducerPage {
  error: Error | null = null
  isFetching: boolean = false
  childIDs: number[] = []
  paginationInfo: PaginationInfo = new PaginationInfo()
  isInitialized: boolean = false
}

const initialState: PaymentsReducerState = {
  byID:                {},
  pages:               {},
  isFetchingByID:      {},
  successFlashMessage: null,
  errorByID:           {},
}

export default (state: PaymentsReducerState = initialState, action: PaymentsActions): PaymentsReducerState | Loop<PaymentsReducerState> => {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENTS: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchPaymentsAction
          const { sorting, page, limit, filters, search } = payload

          // Build page object
          const pageObject = new PaymentsReducerPage()
          pageObject.isFetching = true
          pageObject.isInitialized = true

          // Create a hash key for the page
          const pageKey = hashKeyForPaymentPage(payload)

          // Set state and fetch
          return loop(
            Object.assign({}, state, {
              pages: Object.assign({}, state.pages, {
                [pageKey]: pageObject,
              }),
            }),
            Cmd.run(API.getPayments, {
              successActionCreator: resolveFetchPayments,
              failActionCreator:    rejectFetchPayments,
              args:                 [sorting, page, limit, filters, search],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchPaymentsAction
          const { payments, paginationInfo, requestParams } = payload

          const pageKey = hashKeyForPaymentPage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching:     false,
            error:          null,
            childIDs:       payments.map((payment: Payment) => payment.id),
            paginationInfo: paginationInfo,
          }

          // Map payment ids to payments
          let idMappedPayments: IDMappedPayments = {}
          payments.forEach(payment => {
            idMappedPayments[payment.id] = payment
          })


          // Place in correct page
          return Object.assign({}, state, {
            byID:  Object.assign({}, state.byID, idMappedPayments),
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }

        case false: {
          const { payload } = action as RejectFetchPaymentsAction
          const { error, requestParams } = payload

          // Create a hash key for the page
          const pageKey = hashKeyForPaymentPage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching: false,
            error,
          }

          // Place in correct page
          return Object.assign({}, state, {
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }
      }

      break
    }

    case actionTypes.FETCH_PAYMENT_BY_ID: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchPaymentByIDAction
          const { paymentID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [paymentID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [paymentID]: null,
              },
            },
            Cmd.run(API.getPaymentByID, {
              successActionCreator: resolveFetchPaymentByID,
              failActionCreator:    rejectFetchPaymentByID,
              args:                 [paymentID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchPaymentByIDAction
          const { payment } = payload

          return {
            ...state,
            byID:           {
              ...state.byID,
              [payment.id]: payment,
            },
            isFetchingByID: {
              ...state.isFetchingByID,
              [payment.id]: false,
            },
          }
        }

        case false: {
          const { payload } = action as RejectFetchPaymentByIDAction
          const { error, requestParams } = payload
          const { paymentID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [paymentID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [paymentID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.CREATE_PAYMENT: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as CreatePaymentAction

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [-1]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [-1]: null,
              },
            },
            Cmd.run(API.postPayment, {
              successActionCreator: resolveCreatePayment,
              failActionCreator:    rejectCreatePayment,
              args:                 [payload],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveCreatePaymentAction
          const { payment } = payload

          return loop({
              ...state,
              byID:           {
                ...state.byID,
                [payment.id]: payment,
              },
              isFetchingByID: {
                ...state.isFetchingByID,
                [-1]:                false,
              },
              successFlashMessage: 'Payment successfully created!',
            },
            Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
          )
        }

        case false: {
          const { payload } = action as RejectCreatePaymentAction
          const { error, requestParams } = payload

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [-1]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [-1]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.CLEAR_FLASH_MESSAGES: {
      return {
        ...state,
        successFlashMessage: null,
      }
    }
    
  }

  // Default
  return state
}
