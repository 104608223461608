import { postLogIn, postAdminLogIn } from './authentication'
import { putBranch } from './branches'
import { deleteCategory, getCategoryByID, getCategories, postCategory, putCategory } from './categories'
import { getCustomerVehicleDataCSV, getSalesDataCSV } from './csvDownload'
import { getDashboard } from './dashboard'
import {
  deleteMerchantPointsTransaction,
  getMerchantPointsTransactionByID,
  getMerchantPointsTransactions, postMerchantPointsTransaction, putMerchantPointsTransaction,
} from './merchantPointsTransactions'
import { getPaymentByID, getPayments, postPayment } from './payments'
import { getPointsProgram } from './pointsProgram'
import { getProductCreditBalancesForMerchant } from './productCredits'
import {
  deleteProduct,
  deleteProductVariant,
  getProductByID,
  getProducts,
  postProduct,
  postProductVariant,
  putProduct,
  putProductVariant,
} from './products'
import {
  deleteBundle,
  getBundleByID,
  getBundles,
  postBundle,
  putBundle,
} from './bundles'
import { getStatementOfAccountPDF } from './reports'
import {
  deleteSmsBlast,
  getSmsBlastByID,
  getSmsBlasts,
  postSmsBlast,
  putSmsBlast,
  postSmsBlastSend,
} from './smsBlasts'
import {
  deletePromo,
  getPromoByID,
  getPromos,
  postPurchasePromo,
  putPurchasePromo,
  postLoyaltyPromo,
  putLoyaltyPromo,
} from './promos'
import {
  deleteCustomer,
  getCustomerByID,
  getCustomerIDs,
  getCustomers,
  getCustomerStatsByID, linkVehicleToCustomer,
  postCustomer,
  putCustomer,
} from './customers'
import {
  deleteEmployee,
  getEmployeeByID,
  getEmployees,
  postEmployee,
  putEmployee,
} from './employees'
import {
  deletePartnerAgent,
  getPartnerAgentByID,
  getPartnerAgents,
  postPartnerAgent,
  putPartnerAgent,
} from './partnerAgents'
import {
  deletePaymentAccount,
  getPaymentAccountByID,
  getPaymentAccounts,
  postPaymentAccount,
  putPaymentAccount,
} from './paymentAccounts'
import {
  deleteProductCreditTransaction,
  getProductCreditTransactionByID,
  getProductCreditTransactions,
  postProductCreditTransaction,
  putProductCreditTransaction,
} from './productCreditTransactions'
import { deleteVehicle, getVehicleByID, getVehicles, postVehicle, putVehicle } from './vehicles'
import {
  getInvoices,
  getInvoiceByID,
  deleteInvoice,
  postFullRefundInvoice,
  putInvoicePayment,
  getInvoicePDFByID,
  getInvoiceIDs, getInvoiceActions, postInvoicesFinalize,
} from './invoices'
import { getProfile, postChangePassword, putManager } from './profile'

const api = {
  getCategories,
  getCategoryByID,
  postLogIn,
  postAdminLogIn,
  putCategory,
  deleteCategory,
  postCategory,
  getProducts,
  getProductByID,
  postProduct,
  putProduct,
  deleteProduct,
  postProductVariant,
  putProductVariant,
  deleteProductVariant,
  getPromos,
  getPromoByID,
  postPurchasePromo,
  putPurchasePromo,
  postLoyaltyPromo,
  putLoyaltyPromo,
  deletePromo,
  getInvoices,
  getInvoiceByID,
  getInvoicePDFByID,
  putInvoicePayment,
  deleteCustomer,
  getCustomerByID,
  getCustomerStatsByID,
  getCustomers,
  getCustomerIDs,
  postCustomer,
  putCustomer,
  getDashboard,
  getProfile,
  putManager,
  postChangePassword,
  deleteInvoice,
  postFullRefundInvoice,
  deleteVehicle,
  getVehicleByID,
  getVehicles,
  postVehicle,
  putVehicle,
  getSmsBlasts,
  getSmsBlastByID,
  postSmsBlast,
  putSmsBlast,
  deleteSmsBlast,
  postSmsBlastSend,
  putBranch,
  deleteEmployee,
  getEmployeeByID,
  getEmployees,
  postEmployee,
  putEmployee,
  deletePartnerAgent,
  getPartnerAgentByID,
  getPartnerAgents,
  postPartnerAgent,
  putPartnerAgent,
  deletePaymentAccount,
  getPaymentAccountByID,
  getPaymentAccounts,
  postPaymentAccount,
  putPaymentAccount,
  deleteProductCreditTransaction,
  getProductCreditTransactionByID,
  getProductCreditTransactions,
  postProductCreditTransaction,
  putProductCreditTransaction,
  deleteMerchantPointsTransaction,
  getMerchantPointsTransactionByID,
  getMerchantPointsTransactions,
  postMerchantPointsTransaction,
  putMerchantPointsTransaction,
  getBundles,
  getBundleByID,
  postBundle,
  putBundle,
  deleteBundle,
  getSalesDataCSV,
  getCustomerVehicleDataCSV,
  linkVehicleToCustomer,
  getProductCreditBalancesForMerchant,
  getPayments,
  getPaymentByID,
  getStatementOfAccountPDF,
  getInvoiceIDs,
  postPayment,
  getInvoiceActions,
  postInvoicesFinalize,
  getPointsProgram
}

export default api
