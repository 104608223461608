import { push } from '@lagunovsky/redux-react-router'
import { Cmd, Loop, loop } from 'redux-loop'
import API from '../../api'
import PaginationInfo from '../../model/PaginationInfo'
import MerchantPointsTransaction from '../../model/MerchantPointsTransaction'
import {
  CreateMerchantPointsTransactionAction,
  DeleteMerchantPointsTransactionAction,
  FetchMerchantPointsTransactionByIDAction,
  FetchMerchantPointsTransactionsAction,
  MerchantPointsTransactionsActions,
  rejectCreateMerchantPointsTransaction,
  RejectCreateMerchantPointsTransactionAction,
  rejectDeleteMerchantPointsTransaction,
  RejectDeleteMerchantPointsTransactionAction,
  rejectFetchMerchantPointsTransactionByID,
  RejectFetchMerchantPointsTransactionByIDAction,
  rejectFetchMerchantPointsTransactions,
  RejectFetchMerchantPointsTransactionsAction,
  rejectUpdateMerchantPointsTransaction,
  RejectUpdateMerchantPointsTransactionAction,
  resolveCreateMerchantPointsTransaction,
  ResolveCreateMerchantPointsTransactionAction,
  resolveDeleteMerchantPointsTransaction,
  ResolveDeleteMerchantPointsTransactionAction,
  resolveFetchMerchantPointsTransactionByID,
  ResolveFetchMerchantPointsTransactionByIDAction,
  resolveFetchMerchantPointsTransactions,
  ResolveFetchMerchantPointsTransactionsAction,
  resolveUpdateMerchantPointsTransaction,
  ResolveUpdateMerchantPointsTransactionAction,
  UpdateMerchantPointsTransactionAction,
} from '../actions/merchantPointsTransactions'
import * as actionTypes from '../constants/ActionTypes'
import { CLEAR_FLASH_MESSAGES } from '../constants/ActionTypes'
import { hashKeyForMerchantPointsTransactionPage } from '../selectors/merchantPointsTransactions'

interface IDMappedMerchantPointsTransactions {
  [id: number]: MerchantPointsTransaction
}

interface MerchantPointsTransactionsReducerState {
  byID: IDMappedMerchantPointsTransactions
  pages: { [hash: string]: MerchantPointsTransactionsReducerPage }
  isFetchingByID: { [id: number]: boolean }
  successFlashMessage: string | null
  errorByID: { [id: number]: Error | null }
}

export class MerchantPointsTransactionsReducerPage {
  error: Error | null = null
  isFetching: boolean = false
  childIDs: number[] = []
  paginationInfo: PaginationInfo = new PaginationInfo()
  isInitialized: boolean = false
}

const initialState: MerchantPointsTransactionsReducerState = {
  byID:                {},
  pages:               {},
  isFetchingByID:      {},
  successFlashMessage: null,
  errorByID:           {},
}

export default (state: MerchantPointsTransactionsReducerState = initialState, action: MerchantPointsTransactionsActions): MerchantPointsTransactionsReducerState | Loop<MerchantPointsTransactionsReducerState> => {
  switch (action.type) {

    case actionTypes.FETCH_MERCHANT_POINTS_TRANSACTIONS: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchMerchantPointsTransactionsAction
          const { sorting, page, limit, search } = payload

          // Build page object
          const pageObject = new MerchantPointsTransactionsReducerPage()
          pageObject.isFetching = true
          pageObject.isInitialized = true

          // Create a hash key for the page
          const pageKey = hashKeyForMerchantPointsTransactionPage(payload)

          // Set state and fetch
          return loop(
            Object.assign({}, state, {
              pages: Object.assign({}, state.pages, {
                [pageKey]: pageObject,
              }),
            }),
            Cmd.run(API.getMerchantPointsTransactions, {
              successActionCreator: resolveFetchMerchantPointsTransactions,
              failActionCreator:    rejectFetchMerchantPointsTransactions,
              args:                 [sorting, page, limit, search],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchMerchantPointsTransactionsAction
          const { merchantPointsTransactions, paginationInfo, requestParams } = payload

          const pageKey = hashKeyForMerchantPointsTransactionPage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching:     false,
            error:          null,
            childIDs:       merchantPointsTransactions.map((merchantPointsTransaction: MerchantPointsTransaction) => merchantPointsTransaction.id),
            paginationInfo: paginationInfo,
          }

          // Map merchantPointsTransaction ids to merchantPointsTransactions
          let idMappedMerchantPointsTransactions: IDMappedMerchantPointsTransactions = {}
          merchantPointsTransactions.forEach(merchantPointsTransaction => {
            idMappedMerchantPointsTransactions[merchantPointsTransaction.id] = merchantPointsTransaction
          })


          // Place in correct page
          return Object.assign({}, state, {
            byID:  Object.assign({}, state.byID, idMappedMerchantPointsTransactions),
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }

        case false: {
          const { payload } = action as RejectFetchMerchantPointsTransactionsAction
          const { error, requestParams } = payload

          // Create a hash key for the page
          const pageKey = hashKeyForMerchantPointsTransactionPage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching: false,
            error,
          }

          // Place in correct page
          return Object.assign({}, state, {
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }
      }

      break
    }

    case actionTypes.FETCH_MERCHANT_POINTS_TRANSACTION_BY_ID: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchMerchantPointsTransactionByIDAction
          const { merchantPointsTransactionID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [merchantPointsTransactionID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [merchantPointsTransactionID]: null,
              },
            },
            Cmd.run(API.getMerchantPointsTransactionByID, {
              successActionCreator: resolveFetchMerchantPointsTransactionByID,
              failActionCreator:    rejectFetchMerchantPointsTransactionByID,
              args:                 [merchantPointsTransactionID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchMerchantPointsTransactionByIDAction
          const { merchantPointsTransaction } = payload

          return {
            ...state,
            byID:           {
              ...state.byID,
              [merchantPointsTransaction.id]: merchantPointsTransaction,
            },
            isFetchingByID: {
              ...state.isFetchingByID,
              [merchantPointsTransaction.id]: false,
            },
          }
        }

        case false: {
          const { payload } = action as RejectFetchMerchantPointsTransactionByIDAction
          const { error, requestParams } = payload
          const { merchantPointsTransactionID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [merchantPointsTransactionID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [merchantPointsTransactionID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.UPDATE_MERCHANT_POINTS_TRANSACTION: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as UpdateMerchantPointsTransactionAction
          const { merchantPointsTransactionID} = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID:      {
                ...state.isFetchingByID,
                [merchantPointsTransactionID]: true,
              },
              errorByID:           {
                ...state.errorByID,
                [merchantPointsTransactionID]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.putMerchantPointsTransaction, {
              successActionCreator: resolveUpdateMerchantPointsTransaction,
              failActionCreator:    rejectUpdateMerchantPointsTransaction,
              args:                 [payload],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveUpdateMerchantPointsTransactionAction
          const { merchantPointsTransaction } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [merchantPointsTransaction.id]: merchantPointsTransaction,
              },
              isFetchingByID:      {
                ...state.isFetchingByID,
                [merchantPointsTransaction.id]: false,
              },
              successFlashMessage: 'Partner agent successfully updated!',
            },
            Cmd.list([
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectUpdateMerchantPointsTransactionAction
          const { error, requestParams } = payload
          const { merchantPointsTransactionID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [merchantPointsTransactionID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [merchantPointsTransactionID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.DELETE_MERCHANT_POINTS_TRANSACTION: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as DeleteMerchantPointsTransactionAction
          const { merchantPointsTransactionID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [merchantPointsTransactionID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [merchantPointsTransactionID]: null,
              },
            },
            Cmd.run(API.deleteMerchantPointsTransaction, {
              successActionCreator: resolveDeleteMerchantPointsTransaction,
              failActionCreator:    rejectDeleteMerchantPointsTransaction,
              args:                 [merchantPointsTransactionID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveDeleteMerchantPointsTransactionAction
          const { merchantPointsTransaction } = payload

          // Deletey-poo
          let byID = {
            ...state.byID,
          }
          delete byID[merchantPointsTransaction.id]

          return loop(
            {
              ...state,
              byID,
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [merchantPointsTransaction.id]: false,
              },
              successFlashMessage: 'Partner agent successfully deleted!',
            },
            Cmd.list([
              Cmd.action(push('/product-credit-transactions')),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectDeleteMerchantPointsTransactionAction
          const { error, requestParams } = payload
          const { merchantPointsTransactionID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [merchantPointsTransactionID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [merchantPointsTransactionID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.CREATE_MERCHANT_POINTS_TRANSACTION: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as CreateMerchantPointsTransactionAction

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID:      {
                ...state.isFetchingByID,
                [-1]: true,
              },
              errorByID:           {
                ...state.errorByID,
                [-1]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.postMerchantPointsTransaction, {
              successActionCreator: resolveCreateMerchantPointsTransaction,
              failActionCreator:    rejectCreateMerchantPointsTransaction,
              args:                 [payload],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveCreateMerchantPointsTransactionAction
          const { merchantPointsTransaction } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [merchantPointsTransaction.id]: merchantPointsTransaction,
              },
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [-1]: false,
              },
              successFlashMessage: 'Partner agent successfully created!',
            },
            Cmd.list([
              Cmd.action(push(`/product-credit-transactions/${merchantPointsTransaction.id}`)),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectCreateMerchantPointsTransactionAction
          const { error } = payload

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [-1]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [-1]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.CLEAR_FLASH_MESSAGES: {
      return {
        ...state,
        successFlashMessage: null,
      }
    }

  }

// Default
  return state
}
