import Invoice, { InvoiceFilters, InvoiceJSON, PaymentMethod } from '../model/Invoice'
import PaginationInfo from '../model/PaginationInfo'
import Payment, { PaymentJSON } from '../model/Payment'
import LoopError from '../store/errors/LoopError'
import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, token, urlForEndpoint } from './helpers'

export const getInvoices = async (sorting: string = 'id', page: number = 1, limit: number = 30, filters: InvoiceFilters, search: string): Promise<InvoicesResponse> => {

  let flattenedFilters = getInvoiceFlattenedFilters(filters)

  // Build request
  const url = urlForEndpoint(`invoices`, {
    sorting,
    page,
    limit,
    search,
    ...flattenedFilters,
  })
  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { invoices: invoicesJSON, paginationInfo } = await parseResponse(response)

    let invoices = invoicesJSON.map((invoiceJSON: InvoiceJSON) => new Invoice(invoiceJSON))

    return {
      invoices,
      paginationInfo,
      requestParams: {
        sorting,
        page,
        limit,
        filters,
        search,
      },
    }

  } catch (err) {
    console.error(err)
    throw new LoopError(err, { sorting, page, limit, search })
  }
}

export const getInvoiceByID = async (invoiceID: number): Promise<Invoice> => {

  // Build request
  const url = urlForEndpoint(`invoices/${invoiceID}`)

  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { invoice } = await parseResponse(response)
    return new Invoice(invoice as InvoiceJSON)
  } catch (err) {
    throw new LoopError(err, { invoiceID })
  }

}

const getInvoiceFlattenedFilters = (filters: InvoiceFilters) => {
  // Prepare filters
  let flattenedFilters = {
    paymentMethod:       filters.paymentMethod?.id,
    paymentAccount:      filters.paymentAccount,
    paymentStatus:       filters.paymentStatus?.id,
    dateRange:           JSON.stringify(filters.dateRange),
    customer:            filters.customer,
    sellingPartnerAgent: filters.sellingPartnerAgent,
    sellingEmployee:     filters.sellingEmployee,
    branch:              filters.branch,
    productVariants:     filters.productVariants,
    productVariantsAnd:  filters.productVariantsAnd,
  }
  if (filters.paymentStatus == null) {
    // @ts-ignore
    delete flattenedFilters.paymentStatus
  }
  if (filters.paymentMethod == null) {
    // @ts-ignore
    delete flattenedFilters.paymentMethod
  }
  if (filters.paymentAccount == null) {
    // @ts-ignore
    delete flattenedFilters.paymentAccount
  }
  if (filters.dateRange == null) {
    // @ts-ignore
    delete flattenedFilters.dateRange
  }
  if (filters.sellingEmployee == null) {
    // @ts-ignore
    delete flattenedFilters.sellingEmployee
  }
  if (filters.customer == null) {
    // @ts-ignore
    delete flattenedFilters.customer
  }
  if (filters.sellingPartnerAgent == null) {
    // @ts-ignore
    delete flattenedFilters.sellingPartnerAgent
  }
  if (filters.branch == null) {
    // @ts-ignore
    delete flattenedFilters.branch
  }
  if (filters.productVariants == null) {
    // @ts-ignore
    delete flattenedFilters.productVariants
    // @ts-ignore
    delete flattenedFilters.productVariantsAnd
  }

  return flattenedFilters
}

const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const result = reader.result as string
      resolve(result)
    }
    reader.onerror = () => {
      throw new Error('Failed to read blob to base64')
    }
    reader.readAsDataURL(blob)
  })
}

const base64toBlob = (data: string) => {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  const base64WithoutPrefix = data.substring('data:application/pdf;base64,'.length)

  const bytes = atob(base64WithoutPrefix)
  let length = bytes.length
  let out = new Uint8Array(length)

  while (length--) {
    out[length] = bytes.charCodeAt(length)
  }

  return new Blob([out], { type: 'application/pdf' })
}

export const getInvoicePDFByID = async (invoiceID: number): Promise<Blob> => {

  // Build request
  const url = urlForEndpoint(`invoices/${invoiceID}/pdf`)

  const request = newRequest(HTTPMethods.GET, token())

  // Handle errors and return response
  try {
    const response = await fetchWithErrors(url, request)
    if (response.status != 200) {
      throw new Error('pdf download failed')
    }
    let blobData = await response.blob()


    // return blobData

    let base64Data = await blobToBase64(blobData)

    return base64toBlob(base64Data)
  } catch (err) {
    throw new LoopError(err, { invoiceID })
  }

}


export const deleteInvoice = async (invoiceID: number): Promise<Invoice> => {

  // Build request
  const url = urlForEndpoint(`invoices/${invoiceID}`)

  const request = newRequest(HTTPMethods.DELETE, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { invoice } = await parseResponse(response)
    return new Invoice(invoice as InvoiceJSON)
  } catch (err) {
    throw new LoopError(err, { invoiceID })
  }

}

export const postFullRefundInvoice = async (invoiceID: number, refundReason: string, paymentAccountID: number, transactionID: string): Promise<Invoice> => {

  // Build request
  const url = urlForEndpoint(`invoices/${invoiceID}/full-refund`)
  const request = newRequest(HTTPMethods.POST, token())
  request.body = JSON.stringify({
    refundReason,
    paymentAccountID,
    transactionID,
  })

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { invoice } = await parseResponse(response)
    return new Invoice(invoice as InvoiceJSON)
  } catch (err) {
    throw new LoopError(err, { invoiceID })
  }

}

export const getInvoiceIDs = async (filters: InvoiceFilters, search: string): Promise<InvoiceIDsResponse> => {

  // Prepare filters
  let flattenedFilters = getInvoiceFlattenedFilters(filters)

  // Build request
  const url = urlForEndpoint(`invoices/by-ids`, {
    search,
    ...flattenedFilters,
  })
  const request = newRequest(HTTPMethods.GET, token())

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { invoiceIDs } = await parseResponse(response)

    return {
      invoiceIDs,
      requestParams: {
        filters,
        search,
      },
    }

  } catch (err) {
    throw new LoopError(err, { filters, search })
  }
}

export const getInvoiceActions = async (invoiceIDs: number[]): Promise<InvoiceActionsResponse> => {

  // Build request
  const url = urlForEndpoint(`invoices/by-ids/available-actions`, {
    invoiceIDs,
  })
  const request = newRequest(HTTPMethods.GET, token())

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { payment, finalize } = await parseResponse(response)

    return {
      payment,
      finalize,
    }

  } catch (err) {
    throw new LoopError(err, { invoiceIDs })
  }
}

export const putInvoicePayment = async ({
                                          invoiceID,
                                          paymentID,
                                          paymentMethod,
                                          transactionID,
                                        }: PutInvoicePaymentRequestParams): Promise<PaymentResponse> => {
  // Build request
  const url = urlForEndpoint(`invoices/payments/${paymentID}`)

  const request = newRequest(HTTPMethods.PUT, token())
  request.body = JSON.stringify({
    paymentMethod: paymentMethod.id,
    transactionID,
  })

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { payment, invoiceID } = await parseResponse(response)
    return {
      payment: new Payment(payment as PaymentJSON),
    }
  } catch (err) {
    throw new LoopError(err, { invoiceID, paymentID, paymentMethod, transactionID })
  }
}

export const postInvoicesFinalize = async (params: InvoiceIDsParams): Promise<Invoice[]> => {

  let { invoiceIDs } = params
  // Build request
  const url = urlForEndpoint(`invoices/by-ids/finalize`)

  const request = newRequest(HTTPMethods.POST, token())
  request.body = JSON.stringify({
    invoiceIDs
  })

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { invoices: invoicesJSON } = await parseResponse(response)
    return invoicesJSON.map((invoiceJSON: InvoiceJSON) => new Invoice(invoiceJSON))
  } catch (err) {
    throw new LoopError(err, params)
  }
}

export interface InvoiceActionsResponse {
  payment: boolean
  finalize: boolean
}


export interface InvoicesResponse {
  invoices: Invoice[]
  paginationInfo: PaginationInfo
  requestParams: InvoicesRequestParams
}

export interface InvoicesErrorResponse {
  error: Error
  requestParams: InvoicesRequestParams
}

export interface InvoiceIDsParams {
  invoiceIDs: number[]
}

export interface InvoicesRequestParams {
  sorting: string
  page: number
  limit: number
  filters: InvoiceFilters
  search: string
}

export interface InvoiceByIDRequestParams {
  invoiceID: number
}

export interface InvoiceByIDErrorResponse {
  error: Error
  requestParams: InvoiceByIDRequestParams
}

export interface PaymentResponse {
  payment: Payment
}

export interface PostInvoicePaymentRequestParams {
  invoiceID: number
  paymentMethod: PaymentMethod
  transactionID: string
}

export interface PostInvoicePaymentErrorResponse {
  error: Error
  requestParams: PostInvoicePaymentRequestParams
}

export interface PutInvoicePaymentRequestParams extends PostInvoicePaymentRequestParams {
  paymentID: number
}

export interface PutInvoicePaymentErrorResponse {
  error: Error
  requestParams: PutInvoicePaymentRequestParams
}

export interface PostInvoicesFinalizeErrorResponse {
  error: Error
  requestParams: InvoiceIDsParams
}

export interface InvoiceIDsResponse {
  invoiceIDs: number[]
  requestParams: {
    filters: InvoiceFilters,
    search: string
  }
}

