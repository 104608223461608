import { LocationChangeAction } from '@lagunovsky/redux-react-router'
import {
  MerchantPointsTransactionByIDErrorResponse,
  MerchantPointsTransactionsErrorResponse,
  MerchantPointsTransactionsRequestParams,
  MerchantPointsTransactionsResponse,
  PostMerchantPointsTransactionErrorResponse,
  PostMerchantPointsTransactionRequestParams,
  PutMerchantPointsTransactionErrorResponse,
  PutMerchantPointsTransactionRequestParams,
} from '../../api/merchantPointsTransactions'
import MerchantPointsTransaction from '../../model/MerchantPointsTransaction'
import PaginationInfo from '../../model/PaginationInfo'
import * as types from '../constants/ActionTypes'
import { ClearFlashMessagesAction } from './general'

export interface FetchMerchantPointsTransactionsAction {
  type: typeof types.FETCH_MERCHANT_POINTS_TRANSACTIONS
  success: undefined
  payload: MerchantPointsTransactionsRequestParams
}

export interface ResolveFetchMerchantPointsTransactionsAction {
  type: typeof types.FETCH_MERCHANT_POINTS_TRANSACTIONS
  success: boolean
  payload: {
    merchantPointsTransactions: MerchantPointsTransaction[]
    paginationInfo: PaginationInfo
    requestParams: MerchantPointsTransactionsRequestParams
  }
}

export interface RejectFetchMerchantPointsTransactionsAction {
  type: typeof types.FETCH_MERCHANT_POINTS_TRANSACTIONS
  success: boolean
  payload: {
    error: Error
    requestParams: MerchantPointsTransactionsRequestParams
  }
}

export interface FetchMerchantPointsTransactionByIDAction {
  type: typeof types.FETCH_MERCHANT_POINTS_TRANSACTION_BY_ID
  success: undefined
  payload: {
    merchantPointsTransactionID: number
  }
}

export interface ResolveFetchMerchantPointsTransactionByIDAction {
  type: typeof types.FETCH_MERCHANT_POINTS_TRANSACTION_BY_ID
  success: boolean
  payload: {
    merchantPointsTransaction: MerchantPointsTransaction
  }
}

export interface RejectFetchMerchantPointsTransactionByIDAction {
  type: typeof types.FETCH_MERCHANT_POINTS_TRANSACTION_BY_ID
  success: boolean
  payload: {
    error: Error
    requestParams: {
      merchantPointsTransactionID: number
    }
  }
}

export interface UpdateMerchantPointsTransactionAction {
  type: typeof types.UPDATE_MERCHANT_POINTS_TRANSACTION
  success: undefined
  payload: PutMerchantPointsTransactionRequestParams
}

export interface ResolveUpdateMerchantPointsTransactionAction {
  type: typeof types.UPDATE_MERCHANT_POINTS_TRANSACTION
  success: boolean
  payload: {
    merchantPointsTransaction: MerchantPointsTransaction
  }
}

export interface RejectUpdateMerchantPointsTransactionAction {
  type: typeof types.UPDATE_MERCHANT_POINTS_TRANSACTION
  success: boolean
  payload: PutMerchantPointsTransactionErrorResponse
}


export interface DeleteMerchantPointsTransactionAction {
  type: typeof types.DELETE_MERCHANT_POINTS_TRANSACTION
  success: undefined
  payload: {
    merchantPointsTransactionID: number
  }
}

export interface ResolveDeleteMerchantPointsTransactionAction {
  type: typeof types.DELETE_MERCHANT_POINTS_TRANSACTION
  success: boolean
  payload: {
    merchantPointsTransaction: MerchantPointsTransaction
  }
}

export interface RejectDeleteMerchantPointsTransactionAction {
  type: typeof types.DELETE_MERCHANT_POINTS_TRANSACTION
  success: boolean
  payload: MerchantPointsTransactionByIDErrorResponse
}

export interface CreateMerchantPointsTransactionAction {
  type: typeof types.CREATE_MERCHANT_POINTS_TRANSACTION
  success: undefined
  payload: PostMerchantPointsTransactionRequestParams
}

export interface ResolveCreateMerchantPointsTransactionAction {
  type: typeof types.CREATE_MERCHANT_POINTS_TRANSACTION
  success: boolean
  payload: {
    merchantPointsTransaction: MerchantPointsTransaction
  }
}

export interface RejectCreateMerchantPointsTransactionAction {
  type: typeof types.CREATE_MERCHANT_POINTS_TRANSACTION
  success: boolean
  payload: PostMerchantPointsTransactionErrorResponse
}

export const fetchMerchantPointsTransactions = (sorting: string, page: number, limit: number, search: string): FetchMerchantPointsTransactionsAction => ({
  type:    types.FETCH_MERCHANT_POINTS_TRANSACTIONS,
  success: undefined,
  payload: {
    sorting,
    page,
    limit,
    search,
  },
})

export const resolveFetchMerchantPointsTransactions = ({
                                        merchantPointsTransactions,
                                        paginationInfo,
                                        requestParams,
                                      }: MerchantPointsTransactionsResponse): ResolveFetchMerchantPointsTransactionsAction => ({
  type:    types.FETCH_MERCHANT_POINTS_TRANSACTIONS,
  success: true,
  payload: {
    merchantPointsTransactions,
    paginationInfo,
    requestParams,
  },
})

export const rejectFetchMerchantPointsTransactions = ({ error, requestParams }: MerchantPointsTransactionsErrorResponse): RejectFetchMerchantPointsTransactionsAction => ({
  type:    types.FETCH_MERCHANT_POINTS_TRANSACTIONS,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const fetchMerchantPointsTransactionByID = (merchantPointsTransactionID: number): FetchMerchantPointsTransactionByIDAction => ({
  type:    types.FETCH_MERCHANT_POINTS_TRANSACTION_BY_ID,
  success: undefined,
  payload: {
    merchantPointsTransactionID,
  },
})

export const resolveFetchMerchantPointsTransactionByID = (merchantPointsTransaction: MerchantPointsTransaction): ResolveFetchMerchantPointsTransactionByIDAction => ({
  type:    types.FETCH_MERCHANT_POINTS_TRANSACTION_BY_ID,
  success: true,
  payload: {
    merchantPointsTransaction,
  },
})

export const rejectFetchMerchantPointsTransactionByID = ({
                                          error,
                                          requestParams,
                                        }: MerchantPointsTransactionByIDErrorResponse): RejectFetchMerchantPointsTransactionByIDAction => ({
  type:    types.FETCH_MERCHANT_POINTS_TRANSACTION_BY_ID,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const updateMerchantPointsTransaction = (merchantPointsTransactionID: number, amount: number, customerID: number, notes: string | null): UpdateMerchantPointsTransactionAction => ({
  type:    types.UPDATE_MERCHANT_POINTS_TRANSACTION,
  success: undefined,
  payload: {
    merchantPointsTransactionID,
    amount,
    customerID,
    notes
  },
})

export const resolveUpdateMerchantPointsTransaction = (merchantPointsTransaction: MerchantPointsTransaction): ResolveUpdateMerchantPointsTransactionAction => ({
  type:    types.UPDATE_MERCHANT_POINTS_TRANSACTION,
  success: true,
  payload: {
    merchantPointsTransaction,
  },
})

export const rejectUpdateMerchantPointsTransaction = (payload: PutMerchantPointsTransactionErrorResponse): RejectUpdateMerchantPointsTransactionAction => ({
  type:    types.UPDATE_MERCHANT_POINTS_TRANSACTION,
  success: false,
  payload,
})


export const deleteMerchantPointsTransaction = (merchantPointsTransactionID: number): DeleteMerchantPointsTransactionAction => ({
  type:    types.DELETE_MERCHANT_POINTS_TRANSACTION,
  success: undefined,
  payload: {
    merchantPointsTransactionID,
  },
})

export const resolveDeleteMerchantPointsTransaction = (merchantPointsTransaction: MerchantPointsTransaction): ResolveDeleteMerchantPointsTransactionAction => ({
  type:    types.DELETE_MERCHANT_POINTS_TRANSACTION,
  success: true,
  payload: {
    merchantPointsTransaction,
  },
})

export const rejectDeleteMerchantPointsTransaction = (payload: MerchantPointsTransactionByIDErrorResponse): RejectDeleteMerchantPointsTransactionAction => ({
  type:    types.DELETE_MERCHANT_POINTS_TRANSACTION,
  success: false,
  payload,
})


export const createMerchantPointsTransaction = (amount: number, customerID: number, notes: string | null): CreateMerchantPointsTransactionAction => ({
  type:    types.CREATE_MERCHANT_POINTS_TRANSACTION,
  success: undefined,
  payload: {
    amount,
    customerID,
    notes
  },
})

export const resolveCreateMerchantPointsTransaction = (merchantPointsTransaction: MerchantPointsTransaction): ResolveCreateMerchantPointsTransactionAction => ({
  type:    types.CREATE_MERCHANT_POINTS_TRANSACTION,
  success: true,
  payload: {
    merchantPointsTransaction,
  },
})

export const rejectCreateMerchantPointsTransaction = (payload: PostMerchantPointsTransactionErrorResponse): RejectCreateMerchantPointsTransactionAction => ({
  type:    types.CREATE_MERCHANT_POINTS_TRANSACTION,
  success: false,
  payload,
})

export type MerchantPointsTransactionsActions =
  FetchMerchantPointsTransactionsAction
  | RejectFetchMerchantPointsTransactionsAction
  | ResolveFetchMerchantPointsTransactionsAction
  | FetchMerchantPointsTransactionByIDAction
  | ResolveFetchMerchantPointsTransactionByIDAction
  | RejectFetchMerchantPointsTransactionByIDAction
  | UpdateMerchantPointsTransactionAction
  | RejectUpdateMerchantPointsTransactionAction
  | ResolveUpdateMerchantPointsTransactionAction
  | CreateMerchantPointsTransactionAction
  | ResolveCreateMerchantPointsTransactionAction
  | RejectCreateMerchantPointsTransactionAction
  | DeleteMerchantPointsTransactionAction
  | ResolveDeleteMerchantPointsTransactionAction
  | RejectDeleteMerchantPointsTransactionAction
  | ClearFlashMessagesAction
  | LocationChangeAction
