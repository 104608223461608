import { Money } from 'ts-money'
import {
  PaymentByIDErrorResponse,
  PaymentsErrorResponse,
  PaymentsRequestParams,
  PaymentsResponse, PostPaymentErrorResponse, PostPaymentRequestParams, PostPaymentResponse,
} from '../../api/payments'
import { PostProductErrorResponse, PostProductRequestParams } from '../../api/products'
import PaginationInfo from '../../model/PaginationInfo'
import Payment, { PaymentFilters } from '../../model/Payment'
import Product from '../../model/Product'
import * as types from '../constants/ActionTypes'
import { ClearFlashMessagesAction } from './general'
import { CreateProductAction, RejectCreateProductAction, ResolveCreateProductAction } from './products'


export interface FetchPaymentsAction {
  type: typeof types.FETCH_PAYMENTS
  success: undefined
  payload: PaymentsRequestParams
}

export interface ResolveFetchPaymentsAction {
  type: typeof types.FETCH_PAYMENTS
  success: boolean
  payload: {
    payments: Payment[]
    paginationInfo: PaginationInfo
    requestParams: PaymentsRequestParams
  }
}

export interface RejectFetchPaymentsAction {
  type: typeof types.FETCH_PAYMENTS
  success: boolean
  payload: {
    error: Error
    requestParams: PaymentsRequestParams
  }
}

export interface FetchPaymentByIDAction {
  type: typeof types.FETCH_PAYMENT_BY_ID
  success: undefined
  payload: {
    paymentID: number
  }
}

export interface ResolveFetchPaymentByIDAction {
  type: typeof types.FETCH_PAYMENT_BY_ID
  success: boolean
  payload: {
    payment: Payment
  }
}

export interface RejectFetchPaymentByIDAction {
  type: typeof types.FETCH_PAYMENT_BY_ID
  success: boolean
  payload: {
    error: Error
    requestParams: {
      paymentID: number
    }
  }
}

export interface CreatePaymentAction {
  type: typeof types.CREATE_PAYMENT
  success: undefined
  payload: PostPaymentRequestParams
}

export interface ResolveCreatePaymentAction {
  type: typeof types.CREATE_PAYMENT
  success: boolean
  payload: PostPaymentResponse
}

export interface RejectCreatePaymentAction {
  type: typeof types.CREATE_PAYMENT
  success: boolean
  payload: PostPaymentErrorResponse
}

export const fetchPayments = (sorting: string, page: number, limit: number, filters: PaymentFilters, search: string): FetchPaymentsAction => ({
  type:    types.FETCH_PAYMENTS,
  success: undefined,
  payload: {
    sorting,
    page,
    limit,
    filters,
    search,
  },
})

export const resolveFetchPayments = ({
                                       payments,
                                       paginationInfo,
                                       requestParams,
                                     }: PaymentsResponse): ResolveFetchPaymentsAction => ({
  type:    types.FETCH_PAYMENTS,
  success: true,
  payload: {
    payments,
    paginationInfo,
    requestParams,
  },
})

export const rejectFetchPayments = ({ error, requestParams }: PaymentsErrorResponse): RejectFetchPaymentsAction => ({
  type:    types.FETCH_PAYMENTS,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const fetchPaymentByID = (paymentID: number): FetchPaymentByIDAction => ({
  type:    types.FETCH_PAYMENT_BY_ID,
  success: undefined,
  payload: {
    paymentID,
  },
})

export const resolveFetchPaymentByID = (payment: Payment): ResolveFetchPaymentByIDAction => ({
  type:    types.FETCH_PAYMENT_BY_ID,
  success: true,
  payload: {
    payment,
  },
})

export const rejectFetchPaymentByID = ({
                                         error,
                                         requestParams,
                                       }: PaymentByIDErrorResponse): RejectFetchPaymentByIDAction => ({
  type:    types.FETCH_PAYMENT_BY_ID,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const createPayment = (params: PostPaymentRequestParams): CreatePaymentAction => ({
  type:    types.CREATE_PAYMENT,
  success: undefined,
  payload: params,
})

export const resolveCreatePayment = (payload: PostPaymentResponse): ResolveCreatePaymentAction => ({
  type:    types.CREATE_PAYMENT,
  success: true,
  payload,
})

export const rejectCreatePayment = (payload: PostPaymentErrorResponse): RejectCreatePaymentAction => ({
  type:    types.CREATE_PAYMENT,
  success: false,
  payload,
})

export type PaymentsActions =
  FetchPaymentsAction
  | RejectFetchPaymentsAction
  | ResolveFetchPaymentsAction
  | FetchPaymentByIDAction
  | ResolveFetchPaymentByIDAction
  | RejectFetchPaymentByIDAction
  | ClearFlashMessagesAction
  | CreatePaymentAction
  | ResolveCreatePaymentAction
  | RejectCreatePaymentAction
