import { createSelector } from 'reselect'
import { CustomersRequestParams } from '../../api/customers'
import Customer, { CustomerStats } from '../../model/Customer'
import { RootReducerState } from '../'
import ProductCreditBalance from '../../model/ProductCreditBalance'
import { CustomersReducerPage } from '../reducers/customers'

export const hashKeyForCustomerPage = ({
                                     sorting,
                                     page,
                                     limit,
                                     filters,
                                     search = "",
                                   }: CustomersRequestParams): string => `${page},${limit},${sorting},${JSON.stringify(filters)},${search}`

const customersSelector = (state: RootReducerState): { [id: number]: Customer } => state.customers.byID

const customersStatsSelector = (state: RootReducerState): Map<number, CustomerStats> => state.customers.statsByID

const customersCreditBalancesSelector = (state: RootReducerState): Map<number, Array<ProductCreditBalance>> => state.customers.creditBalancesByID

const customersMerchantPointsBalancesSelector = (state: RootReducerState): Map<number, number> => state.customers.merchantPointsBalanceByID
const customerStatsIsFetchingByIDSelector = (state: RootReducerState): Map<number, boolean> => state.customers.isFetchingStatsByID

const errorsSelector = (state: RootReducerState): { [id: number]: Error | null } => state.customers.errorByID
const customerIsFetchingByIDSelector = (state: RootReducerState): { [id: number]: boolean } => state.customers.isFetchingByID

const customerPageSelector = (state: RootReducerState, props: any): CustomersReducerPage => state.customers.pages[hashKeyForCustomerPage(props)] || new CustomersReducerPage()
export const customerFlashMessageSelector = (state: RootReducerState): string | null => state.customers.successFlashMessage

// TODO: fix for react router
export const customerIDFromPropsSelector = (state: RootReducerState, props: any): number => {
  return +props.customerID || -1
}

export const customerWithIDFromProps = createSelector(
  [customersSelector, customerIDFromPropsSelector],
  (customers, customerID) => customers[customerID],
)

export const customerStatsWithIDFromProps = createSelector(
  [customersStatsSelector, customerIDFromPropsSelector],
  (customerStats, customerID) => customerStats.get(customerID),
)

export const customerCreditBalancesWithIDFromProps = createSelector(
  [customersCreditBalancesSelector, customerIDFromPropsSelector],
  (customerCreditBalances, customerID) => customerCreditBalances.get(customerID),
)

export const customerMerchantPointsBalanceWithIDFromProps = createSelector(
  [customersMerchantPointsBalancesSelector, customerIDFromPropsSelector],
  (customerMerchantPointsBalances, customerID) => customerMerchantPointsBalances.get(customerID),
)

export const isFetchingStatsForCustomerWithIDFromProps = createSelector(
  [customerStatsIsFetchingByIDSelector, customerIDFromPropsSelector],
  (isFetching, customerID) => isFetching.get(customerID) || false,
)

export const errorForCustomerWithIDFromProps = createSelector(
  [errorsSelector, customerIDFromPropsSelector],
  (customers, customerID) => customers[customerID],
)

export const isFetchingForCustomerWithIDFromProps = createSelector(
  [customerIsFetchingByIDSelector, customerIDFromPropsSelector],
  (customers, customerID) => customers[customerID] || false,
)

export const customersByPageSelector = createSelector(
  [customersSelector, customerPageSelector],
  (customers, page) => {
    if (page == null || page.childIDs == null) {
      return null
    }
    return page.childIDs.map(customerID => customers[customerID])
  },
)

export const paginationInfoByPageSelector = createSelector(
  [customerPageSelector],
  (page) => page.paginationInfo,
)

export const pageIsFetchingSelector = createSelector(
  [customerPageSelector],
  (page) => page.isFetching || false,
)

export const pageIsInitializedSelector = createSelector(
  [customerPageSelector],
  (page) => page.isInitialized || false,
)

export const pageErrorSelector = createSelector(
  [customerPageSelector],
  (page) => page.error || null,
)

